import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Investor from "./components/investor";
import Main from "./components/main";
import Eligible from "./components/main/Eligible";
import FaqEligibility from "./components/main/FaqEligibility";
import FaqInvestor from "./components/main/FaqInvestor";
import FaqOther from "./components/main/FaqOther";
import FaqProcess from "./components/main/FaqProcess";
import FaqQuery from "./components/main/FaqQuery";
import FaqStartup from "./components/main/FaqStartup";
import Forgot from "./components/main/Forgot";
import Home from "./components/main/Home";
import Option from "./components/main/Option";
import Scout from "./components/main/Scout";
import Signin from "./components/main/Signin";
import Signup from "./components/main/Signup";
import Subscription from "./components/main/Subscription";
import Startup from "./components/startup";

import About from "./components/main/About";
import Contact from "./components/main/Contact";
import Faq from "./components/main/Faq";
import Testimonial from "./components/main/Testimonial";
import NotFound from "./components/NotFound";
import StartupChat from "./components/startup/Chat";
import User from "./components/user";
import UserProfile from "./components/user/UserProfile";
import UserProvider from "./context/UserProvider";

import InvestorDetails from "./components/investor/InvestorDetails";
import InvestorList from "./components/investor/InvestorList";
import InvesterListComingSoon from "./components/investor/InvesterListComingSoon";
import Registerinvestor from "./components/investor/Register";
import BlogBrowser from "./components/main/BlogBrowser";
import BlogDetail from "./components/main/BlogDetail";
import CampaignBrowser from "./components/main/CampaignBrowser";
import CampaignDetail from "./components/main/CampaignDetail";
import Consultancy from "./components/main/Consultancy";
import ConsultancyCard from "./components/main/ConsultancyCard";
import NewsBrowser from "./components/main/NewsBrowser";
import NewsDetail from "./components/main/NewsDetail";
import StartupDashboard from "./components/startup/Dashboard";
import LeanCanvasModel from "./components/startup/LeanCanvasModel";
import Register from "./components/startup/Register";
import StartupDetails from "./components/startup/StartupDetails";
import StartupList from "./components/startup/StartupList";
import IntroAI from "./components/startup/IntroAI";
import StartupQuestionnaire from "./components/startupQuestionnarie/index";

// import StartupDashboardContainer from "./components/startup/DashboardContainer";
import Auth from "./auth/Auth";
import InvestorAuth from "./auth/InvestorAuth";
import LoginAuth from "./auth/LoginAuth";
import StartupAuth from "./auth/StartupAuth";
import InvestorChat from "./components/investor/Chat";
import ConsultancyInvestor from "./components/investor/Consultancy";
import InvestorDashboard from "./components/investor/Dashboard";
import InvestorInbox from "./components/investor/InvestorInbox";
import PlanDetails from "./components/investor/PlanDetails";
import ProfileDetail from "./components/investor/ProfileDetail";
import NewsEvents from "./components/main/NewsEvent";
import NewsEventDetail from "./components/main/NewsEventDetail";
import ResetPassword from "./components/main/ResetPassword";
import VerifyEmail from "./components/main/VerifyEmail";
import Checkout from "./components/startup/Checkout";
import FaqBoard from "./components/startup/FaqBoard";
import MyProfile from "./components/startup/MyProfile";
import Notification from "./components/startup/Notification";
import StartupInbox from "./components/startup/StartupInbox";
import StartupProfile from "./components/startup/StartupProfile";
import InvestorProvider from "./context/InvestorProvider";
import StartupProvider from "./context/StartupProvider";
import { SocketProvider } from "./context/SocketProvider";

function App() {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const currentStartup = JSON.parse(sessionStorage.getItem("startup"));
  const currentInvestor = JSON.parse(sessionStorage.getItem("investor"));

  const handleRedirect = () => {
    if (currentStartup) {
      return <Navigate to="/startup/investorlist" />;
    } else if (currentInvestor) {
      return <Navigate to="/investor/startuplist" />;
    } else {
      return <Navigate to="/main/home" />;
    }
  };

  return (
    <BrowserRouter>
      <UserProvider currentUser={currentUser}>
        <StartupProvider currentStartup={currentStartup}>
          <InvestorProvider currentStartup={currentInvestor}>
            <SocketProvider>
              <Routes>
                <Route element={handleRedirect()} path="/" />
                <Route element={<Main />} path="main">
                  <Route element={<Checkout />} path="checkout/:sessionid" />
                  <Route element={<Home />} path="home" />
                  <Route element={<Scout />} path="scout" />
                  <Route element={<Forgot />} path="forgot" />
                  <Route
                    element={<VerifyEmail />}
                    path="verifyemail/:verificationToken"
                  />
                  <Route
                    element={
                      <LoginAuth>
                        <Signin />
                      </LoginAuth>
                    }
                    path="login"
                  />
                  <Route element={<Option />} path="option" />
                  <Route element={<Signup />} path="signup" />
                  <Route element={<About />} path="aboutus" />
                  <Route element={<Eligible />} path="Eligible" />
                  <Route element={<Subscription />} path="subscription" />
                  <Route
                    element={<ResetPassword />}
                    path="reset-password/:verificationToken"
                  />
                  <Route
                    element={<CampaignDetail />}
                    path="campaigndetails/:id"
                  />
                  <Route element={<BlogDetail />} path="blogdetails/:id" />
                  <Route element={<ConsultancyCard />} path="consultancy" />
                  <Route element={<Consultancy />} path="chat" />
                  <Route element={<Contact />} path="contact" />
                  <Route element={<Testimonial />} path="test" />
                  <Route element={<Faq />} path="faq" />
                  <Route element={<FaqQuery />} path="query" />
                  <Route element={<FaqStartup />} path="faqstartup" />
                  <Route element={<FaqInvestor />} path="faqinvestor" />
                  <Route element={<FaqEligibility />} path="faqeligibilty" />
                  <Route element={<FaqProcess />} path="faqprocess" />
                  <Route element={<FaqOther />} path="faqother" />
                  <Route element={<NewsEvents />} path="newsbrowser" />
                  <Route element={<NewsEventDetail />} path="newsbrowser/:id" />
                  <Route element={<BlogBrowser />} path="blogbrowser" />
                  <Route element={<CampaignBrowser />} path="campaignbrowser" />
                  <Route
                    element={
                      <Auth>
                        <StartupList />
                      </Auth>
                    }
                    path="startuplist"
                  />

                  <Route
                    element={
                      <Auth>
                        <StartupDetails />
                      </Auth>
                    }
                    path="startupdetails/:id"
                  />

                  <Route
                    element={
                      <Auth>
                        <InvesterListComingSoon />
                      </Auth>
                    }
                    path="investorlist"
                  />

                  <Route
                    element={
                      <Auth>
                        <InvestorDetails />
                      </Auth>
                    }
                    path="investordetails/:id"
                  />
                </Route>

                <Route
                  element={<StartupQuestionnaire />}
                  path="/startup/questionnaire"
                />

                <Route
                  element={
                    <StartupAuth>
                      <Startup />
                    </StartupAuth>
                  }
                  path="startup"
                >
                  <Route element={<StartupDashboard />} path="dashboard" />
                  <Route element={<Register />} path="register" />
                  <Route element={<LeanCanvasModel />} path="leancanvasmodel" />
                  <Route element={<Notification />} path="notifications" />
                  <Route element={<IntroAI />} path="introai" />
                  <Route element={<StartupChat />} path="chat/:investorid" />
                  <Route element={<StartupInbox />} path="inbox" />
                  <Route element={<StartupProfile />} path="startupprofile" />
                  <Route element={<Register />} path="profile" />
                  <Route
                    element={<InvesterListComingSoon />}
                    path="investorlist"
                  />
                  <Route element={<MyProfile />} path="myprofile/:id" />
                  <Route
                    element={<InvestorDetails />}
                    path="investordetails/:id"
                  />
                  <Route element={<StartupDetails />} path="introai/:id" />
                  <Route element={<NewsBrowser />} path="newsbrowser" />
                  <Route element={<NewsDetail />} path="newsbrowser/:id" />
                  <Route element={<FaqBoard />} path="faq" />
                  <Route element={<FaqProcess />} path="faqprocess" />
                </Route>
                <Route
                  element={
                    <InvestorAuth>
                      {" "}
                      <Investor />
                    </InvestorAuth>
                  }
                  path="investor"
                >
                  <Route element={<InvestorDashboard />} path="dashboard" />
                  <Route element={<chatlist />} path="chatlist" />
                  <Route element={<InvestorChat />} path="chat/:startupid" />
                  <Route element={<Registerinvestor />} path="profile" />
                  <Route element={<PlanDetails />} path="plan" />
                  <Route element={<StartupList />} path="startuplist" />
                  <Route element={<ConsultancyInvestor />} path="consultancy" />

                  <Route element={<NewsBrowser />} path="newsbrowser" />
                  <Route element={<NewsDetail />} path="newsbrowser/:id" />
                  <Route element={<FaqBoard />} path="faq" />
                  <Route element={<FaqProcess />} path="faqprocess" />
                  <Route element={<ProfileDetail />} path="myprofile/:id" />
                  <Route
                    element={<StartupDetails />}
                    path="startupdetails/:id"
                  />
                  <Route element={<InvestorInbox />} path="inbox" />
                </Route>

                <Route element={<User />} path="user">
                  <Route path="profile" element={<UserProfile />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </SocketProvider>
          </InvestorProvider>
        </StartupProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
