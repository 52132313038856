import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import app_config from "../../config";

import { findCountryLabel } from "../commons/countries";

const IntroAI = () => {
  const [filteredStartupList, setFilteredStartupList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let navigate = useNavigate();
  const profilesPerPage = 10;

  const url = app_config.apiurl;
  const placeholder =
    "https://via.placeholder.com/640x400.png/a59090/000000?Text=640x400";

  const token = sessionStorage.getItem("token");
  let currentUser = JSON.parse(sessionStorage.getItem("startup"));

  const fetchData = async () => {
    const response = await fetch(
      `${url}/introai/introai_users/${currentUser._id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    // console.log(data, "strt");

    if (response.ok && Array.isArray(data)) {
      setFilteredStartupList(data);
    } else {
      setFilteredStartupList([]); // Set to an empty array if no recommendations or invalid token
    }
  };

  useEffect(() => {
    fetchData();
    setCurrentPage(1);
  }, []);

  const isAvatarImageAvailable = (imageUrl) =>
    imageUrl && imageUrl.trim().length > 0 && imageUrl !== "undefined";

  const displayList = () => {
    const startIndex = (currentPage - 1) * profilesPerPage;
    const endIndex = startIndex + profilesPerPage;
    const currentList = filteredStartupList.slice(startIndex, endIndex);

    return currentList.map((startup, i) => {
      // const hasMatchingInterests = startup.interested_in.some(interest =>
      //   currentUser.interested_in.includes(interest)
      // );

      // const hasMatchingSupport = startup.support_needed.some(support =>
      //   currentUser.support_needed.includes(support)
      // );

      // const hasMatchingSkills = startup.strongest_skills.some(skill =>
      //   currentUser.strongest_skills.includes(skill)
      // );

      // const isMatching = hasMatchingInterests || hasMatchingSupport || hasMatchingSkills;

      return (
        <div key={startup._id} className="bg-[#fff]  rounded relative">
          {i < 5 && currentPage === 1 && (
            <div className="bg-skincolor bg-opacity-60 px-2 py-1 rounded-md w-fit text-xs font-semibold absolute -top-3 right-3 z-0">
              New Intro
            </div>
          )}

          <div
            className={`flex flex-row py-3 items-center h-full ${i < 5 && currentPage === 1 && "border-2 border-skincolor"} border-opacity-60   bg-white z-20`}
          >
            <div className="col-md-4 w-[30%] flex flex-col gap-2 items-center justify-center ">
              <img
                className="px-1 py-1 w-24 h-24 rounded-full"
                src={
                  isAvatarImageAvailable(startup.startupimage)
                    ? startup.startupimage
                    : placeholder
                }
                alt="Startup"
              />
              <div>
                <Link
                  type="button"
                  className=" text-xs md:text-sm rounded-md px-3 font-normal py-1 text-black bg-skincolor hover:bg-opacity-80  "
                  to={`/startup/introai/${startup._id}`}
                >
                  View
                  <span className="hidden md:inline-block ml-1">Profile</span>
                </Link>
              </div>
            </div>
            <div className="w-[70%] px-1 md:ml-16 lg:ml-1 text-xs md:text-sm">
              <h1 className="md:text-xl capitalize text-lg">
                <strong>{startup.firstName}</strong>
              </h1>
              <p className="text-[#808080]  mt-1">
                <strong>
                  {findCountryLabel(startup.location) || "Not Available"}
                </strong>
              </p>
              <div className="flex flex-row mt-2 items-center">
                <p className="font-bold ">
                  Interested In:
                  <span className={`md:hidden font-normal ml-2`}>
                    {startup.interested_in.join(" , ") || "Not Available"}
                  </span>
                </p>
                <p className={`hidden md:ml-2 md:block text-sm`}>
                  {startup.interested_in.join(" , ") || "Not Available"}
                </p>
              </div>
              <div className="flex flex-row mt-1 items-center">
                <p className="font-bold ">
                  Support Required:
                  <span className="md:hidden font-normal ml-2">
                    {startup.support_needed.join(" , ") || "Not Available"}
                  </span>
                </p>
                <p className="hidden md:block md:ml-2 ">
                  {startup.support_needed.join(" , ") || "Not Available"}
                </p>
              </div>
              <div className="flex flex-row mt-1 items-center">
                <p className="font-bold ">
                  Strongest Skills:
                  <span className="md:hidden font-normal ml-2">
                    {startup.strongest_skills.join(" , ") || "Not Available"}
                  </span>
                </p>
                <p className="hidden md:block md:ml-2 ">
                  {startup.strongest_skills.join(" , ") || "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredStartupList.length / profilesPerPage);
    if (totalPages <= 1) return null;

    const generatePageNumbers = () => {
      const visiblePages = [];
      if (totalPages <= 5) {
        // Show all pages if total pages are 5 or less
        for (let i = 1; i <= totalPages; i++) visiblePages.push(i);
      } else {
        // First page
        visiblePages.push(1);

        // Ellipsis before current page
        if (currentPage > 3) visiblePages.push("...");

        // Middle range (current page ±1)
        for (
          let i = Math.max(currentPage - 1, 2);
          i <= Math.min(currentPage + 1, totalPages - 1);
          i++
        ) {
          visiblePages.push(i);
        }

        // Ellipsis after current page
        if (currentPage < totalPages - 2) visiblePages.push("...");

        // Last page
        visiblePages.push(totalPages);
      }
      return visiblePages;
    };

    const pageNumbers = generatePageNumbers();

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={`ellipsis-${index}`} className="px-2 py-1 mx-1">
              ...
            </span>
          ) : (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className={`px-2 py-1 mx-1 border rounded ${
                currentPage === number
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              {number}
            </button>
          ),
        )}
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "-135px",
          display: "flex",
        }}
        className="h-24 sm:h-60 md:justify-start justify-center"
      >
        <h1 className="text-black md:ml-7 pt-6 text-4xl font-semibold mt-3 sm:mt-0">
          Intro AI
        </h1>
        <div></div>
      </div>

      {filteredStartupList.length !== 0 && (
        <div className="flex flex-row mt-20">
          <p className="ml-6 md:!-mt-20 hidden md:block">
            Get more intros to interesting people automatically. Check New
            Intros every monday and thursday
            <span
              className="ml-2 cursor-pointer bg-skincolor px-4 py-2 rounded-md"
              onClick={() => navigate(`/startup/myprofile/${currentUser._id}`)}
            >
              <i className="fas fa-edit"></i> Edit
            </span>
          </p>
        </div>
      )}

      {filteredStartupList.length === 0 ? (
        <div className="flex justify-center items-center h-screen">
          <div className="flex flex-col justify-center text-center md:-mt-60">
            <p className="text-lg text-gray-600 mb-2 mx-6 md:mx-0 md:w-[50rem]">
              Welcome to intro AI. Get connected with other professionals
              automatically and improve your network. To start getting intros,
              visit edit profile
            </p>
            <div className="flex justify-center">
              <button
                className="flex items-center gap-2 px-4 py-2 mt-2 bg-skincolor text-black font-medium rounded-lg"
                onClick={() =>
                  navigate(`/startup/myprofile/${currentUser._id}`)
                }
              >
                <i className="fas fa-edit"></i> Edit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-start items-start md:ml-6 md:-mt-10 mt-16 mb-4 ml-5">
            <p className="md:hidden block">
              Get more intros to interesting people automatically. Check New
              Intros every monday and thursday
              <span
                className="underline ml-2 cursor-pointer"
                onClick={() =>
                  navigate(`/startup/myprofile/${currentUser._id}`)
                }
              >
                <i className="fas fa-edit"></i> Edit
              </span>
            </p>
            <div className="flex justify-start md:-ml-1 md:mt-0 mb-2 -ml-2">
              {renderPagination()}
            </div>
          </div>

          <div className="flex justify-center md:justify-start mb-6">
            <div className="ml-2 md:ml-6 w-[90%] md:w-[85%] 2xl:w-[65%]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {displayList()}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IntroAI;
