import { format } from "timeago.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Message({ message, self }) {
  const isSelf = self === message.sentBy;

  return (
    <li
      className={classNames(
        isSelf ? "justify-end" : "justify-start",
        "flex my-2 animate-fadeIn",
      )}
    >
      <div
        className={classNames(
          isSelf ? "ml-8 md:ml-20" : "mr-8 md:mr-20",
          "relative max-w-[75%] md:max-w-[60%] group ",
        )}
      >
        <div
          className={classNames(
            isSelf
              ? "bg-accept text-white rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl "
              : "bg-white text-gray-800 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl border border-gray-100 ",
            "px-3 py-1.5 shadow-sm hover:shadow-md transition-shadow duration-200 ",
            "break-all",
          )}
        >
          <div
            className={classNames(
              isSelf ? "flex-row" : "flex-row",
              "flex items-end gap-2 ",
            )}
          >
            <p className="text-sm md:text-base font-normal leading-relaxed flex-1 break-words whitespace-pre-wrap">
              {message.message}
            </p>
            <span
              className={classNames(
                isSelf ? "text-blue-50" : "text-gray-400",
                "text-[10px] font-light whitespace-nowrap self-end shrink-0",
              )}
            >
              {new Date(message.date).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
}
