import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import app_config from "../../config";
import ChatForm from "./ChatForm";
import ChatListItemCard from "./ChatListItemCard";
import Message from "./Message";
import { io } from "socket.io-client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSocket } from "../../context/SocketProvider";

export default function ChatRoom({ user, onBack, updateContactsOnMessage }) {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const currentUser =
    JSON.parse(sessionStorage.getItem("investor")) ||
    JSON.parse(sessionStorage.getItem("startup"));
  const socket = useSocket();
  useEffect(() => {
    const fetchChats = async () => {
      const res = await fetch(
        `${app_config.apiurl}/chat/getchat/${currentUser._id}/${user._id}`,
      );
      const chatsData = await res.json();
      console.log(chatsData);
      setMessages(chatsData.result);
    };

    fetchChats();
  }, [currentUser._id, user._id]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("addtocontact", currentUser._id);

    socket.on("recmsg", (data) => {
      setMessages([...messages, data]);
      updateContactsOnMessage(data);
    });

    return () => socket.off("recmsg");
  }, [socket, updateContactsOnMessage]);

  const handleFormSubmit = async (message) => {
    if (!message.trim()) return;
    const formData = {
      users: [currentUser._id, user._id],
      sentBy: currentUser._id,
      date: new Date(),
      to: user._id,
      message: message,
      name: currentUser.name,
    };

    socket.emit("sendmsg", formData);
    setMessages((prevMessages) => [...prevMessages, formData]);
    updateContactsOnMessage(formData);

    await fetch(`${app_config.apiurl}/chat/add`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  };

  const handleCardClick = () => {
    if (currentUser.role === "startup") {
      if (user.role === "investor") {
        navigate(`/startup/investordetails/${user._id}`);
      } else {
        navigate(`/startup/introai/${user._id}`);
      }
    } else {
      navigate(`/investor/startupdetails/${user._id}`);
    }
  };

  return (
    <div className="lg:col-span-2 lg:block h-[79vh] flex flex-col md:h-[72vh] ">
      {" "}
      <div className="w-full h-full flex flex-col">
        <div
          className="p-3 bg-white border-b border-gray-200 flex items-center"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <button className="block md:hidden mr-4" onClick={onBack}>
            <ArrowBackIosIcon />
          </button>
          <div onClick={handleCardClick} className="cursor-pointer">
            <ChatListItemCard user={user} room={true} />
          </div>
        </div>

        <div
          className="flex-1 w-full max-h-[64vh] md:max-h-[23rem] xl:max-h-[30rem] p-6 overflow-y-auto overflow-x-hidden scrollbar-custom bg-chatbackground border-b border-gray-200 flex flex-col-reverse"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <ul className="space-y-2">
            {messages.map((message, index) => (
              <div key={index}>
                <Message message={message} self={currentUser._id} />
              </div>
            ))}
          </ul>
        </div>

        <div className="w-full">
          <ChatForm handleFormSubmit={handleFormSubmit} />
        </div>
      </div>
    </div>
  );
}
