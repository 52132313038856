import { Formik } from "formik";
import { MDBInput } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import { useStartupContext } from "../../context/StartupProvider";
import Header from "./Header";

const Signin = () => {
  const { setSloggedIn } = useStartupContext();

  console.log(app_config.apiurl, "api");

  // signin schema validation
  const SigninSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });
  ///////////////////////////////
  const navigate = useNavigate();

  const userSubmit = async (formdata, { setSubmitting }) => {
    console.log(formdata);
    setSubmitting(true);

    const res = await fetch(`${app_config.apiurl}/users/auth`, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: { "Content-Type": "application/json" },
    });

    console.log(res.status);
    setSubmitting(false);
    const data = await res.json();
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "You have logged in successfully",
      }).then(() => handleLoginSuccess(data));
    } else {
      let errorMessage =
        data.error || "Unable to process your request. Please try again later.";
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    }
  };

  const isOnboardingCompleted = (userData) => {
    return (
      userData.startupimage != null &&
      userData.firstName != null &&
      userData.type != null &&
      userData.location != null &&
      userData.industry != null &&
      userData.interests != null &&
      userData.brief != null &&
      userData.startupStages != null &&
      userData.startupStages.length > 0
    );
  };

  const handleLoginSuccess = async (response) => {
    let role = response.result.role;
    sessionStorage.setItem(role, JSON.stringify(response.result));
    sessionStorage.setItem("token", response.token);
    if (role === "startup") {
      const userData = response.result;
      if (isOnboardingCompleted(userData)) {
        navigate("/startup/introai");
      } else {
        setSloggedIn(true); // Mark user as logged in
        navigate("/startup/questionnaire");
      }
    } else if (role === "investor") {
      navigate("/investor/startuplist");
    }
  };

  return (
    <div className="bg-white">
      <Header />
      <div className="flex justify-center mt-20 md:mt-0 md:items-center h-[88vh]">
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* Left Section: Form */}
            <div className="flex flex-col justify-center items-center">
              <p className="text-center text-xl font-semibold text-black mb-4">
                Login
              </p>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={SigninSchema} //Validation Schema
                onSubmit={userSubmit}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Email input */}
                    <div className="md:w-80 w-64">
                      <MDBInput
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-deny">{errors.email}</div>
                      ) : null}
                    </div>

                    {/* Password input */}
                    <div>
                      <MDBInput
                        label="Password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-deny">{errors.password}</div>
                      ) : null}
                    </div>

                    {/* Submit Button */}
                    <div>
                      <button
                        className="w-full py-2 bg-skincolor font-medium text-sm text-black rounded-md"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>

                    {/* Forgot Password Link */}
                    <div className="text-center">
                      <a
                        href="/main/forgot"
                        className="hover:text-blue-500 hover:underline"
                      >
                        Forgot password?
                      </a>
                    </div>

                    {/* Signup Link */}
                    <div className="text-center">
                      <a
                        href="/main/signup"
                        className="hover:text-blue-500 hover:underline"
                      >
                        New User? Create Account
                      </a>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            {/* Right Section: Image */}
            <div className="hidden md:flex">
              <img
                src="/login-man1.png"
                alt="Login illustration"
                className="w-full h-[88vh] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
