export function convertToReadableDate(dateString) {
  if (!dateString) {
    return dateString;
  }
  const date = new Date(dateString);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export const checkProfileImageExist = (imageUrl) => {
  // console.log("imageUrl:" + imageUrl);
  return (
    imageUrl !== undefined &&
    imageUrl !== null &&
    imageUrl !== "undefined" &&
    imageUrl.trim().length > 0
  );
};

export const isAvatarImageAvailable = (imageUrl) => {
  console.log("imageUrl:" + imageUrl);
  return (
    imageUrl !== undefined &&
    imageUrl !== null &&
    imageUrl !== "undefined" &&
    imageUrl.trim().length > 0
  );
};

export const formatMessageTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = now - date;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  if (diffInHours < 24) {
    const diffInMinutes = diffInMs / (1000 * 60);
    if (diffInMinutes < 60) {
      if (diffInMinutes < 1) {
        return "Just now";
      }
      return `${Math.floor(diffInMinutes)} minutes ago`;
    }
    return `${Math.floor(diffInHours)} hours ago`;
  }

  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
