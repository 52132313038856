import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import {
  startupIndustries,
  startupStages,
  introAIOptions,
} from "../../Constants";
import { countries } from "../commons/countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Define the options for the interests field
const interestOptions = [
  "Government",
  "Discovery",
  "Manufacturing",
  "Marketplace",
  "Mobile",
  "Platform",
  "Consulting",
  "Engineering",
  "Others",
  "Rental",
  "Research",
];

const Schema1 = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").required("Name is required"),
  startupName: Yup.string()
    .min(2, "Too Short!")
    .required("Startup Name is required"),
  brief: Yup.string()
    .required("Description is required")
    .test(
      "wordCount",
      "Description cannot exceed 150 words",
      (value) => value && value.split(/\s+/).length <= 150,
    ),
  startupStages: Yup.string().required("Startup stage is required"),
  interests: Yup.array()
    .min(1, "At least one interest is required")
    .required("Please select at least one interest"),
});

const Schema3 = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  tel: Yup.string().max(10).required("contact number is required"),
});

const Schema5 = Yup.object().shape({
  intro_message: Yup.string()
    .required("Intro Message is required")
    .test(
      "wordCount",
      "Intro Message cannot exceed 50 words",
      (value) => value && value.trim().split(/\s+/).length <= 50,
    ),
  interested_in: Yup.array().min(1, "Select at least one interest"),
  strongest_skills: Yup.array().min(1, "Select at least one strongest skill"),
  years_of_experience: Yup.string().required("Experience is required"),
  support_needed: Yup.array().min(1, "Select at least one support area"),
});

const Register = ({ onUpdate }) => {
  const url = app_config.apiurl;
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = JSON.parse(sessionStorage.getItem("startup"));

    return savedUser
      ? {
          ...savedUser,
          interests: Array.isArray(savedUser.interests)
            ? savedUser.interests
            : savedUser.interests
              ? savedUser.interests.split(", ")
              : [],
        }
      : {};
  });

  //const [selimage, setSelimage] = useState("");

  const [startUpImage, setStartUpImage] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);

  const token = sessionStorage.getItem("token");

  const updateUser = async (values, { setSubmitting }) => {
    // Convert selected interests array to a comma-separated string
    values.interests = Array.isArray(values.interests)
      ? values.interests.join(", ")
      : values.interests;

    // Ensure other multi-select values are properly formatted (in array form)
    values.interested_in = Array.isArray(values.interested_in)
      ? values.interested_in
      : [];
    values.strongest_skills = Array.isArray(values.strongest_skills)
      ? values.strongest_skills
      : [];
    values.support_needed = Array.isArray(values.support_needed)
      ? values.support_needed
      : [];

    if (startUpImage) {
      let url = await uploadImage(startUpImage);
      values.startupimage = url;
    }
    if (documentFile) {
      let url = await uploadImage(documentFile);
      values.documents = url;
    }

    setSubmitting(true);
    const res = await fetch(
      `${app_config.apiurl}/users/update/${currentUser._id}`,
      {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(res.status);
    setSubmitting(false);

    if (res.status === 200) {
      const data = await res.json();
      // Ensure new interests are formatted as an array for further use
      const updatedUser = {
        ...data.result,
        interests: data.result.interests
          ? data.result.interests.split(", ")
          : [],
      };
      setCurrentUser(updatedUser);
      sessionStorage.setItem("startup", JSON.stringify(updatedUser));
      onUpdate();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Saved Successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill the required details",
      });
    }
  };
  const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append("myfile", file);
    const res = await fetch(url + "/util/uploadfile", {
      method: "POST",
      body: fd,
    });
    let response = await res.json();
    return res.status === 200 ? response.url : null;
  };

  const handleFileChange = (setter, e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file && file.size > maxSizeInBytes) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "File size exceeds the maximum limit of 5MB.",
      });
    } else {
      setter(file);
    }
  };

  return (
    <>
      <div
        className="md:ml-6 md:mx-12 mx-8 mt-4 md:!mt-0"
        style={{ height: "fit-content", width: "fit-content" }}
      >
        <h1 className="text-black md:text-4xl text-3xl font-semibold sm:mt-0">
          Edit Profile
        </h1>
        {/* <!-- Tabs navs --> */}
        <ul
          className="nav nav-tabs nav-justified md:space-x-5 mt-3 md:mb-7 mb-4"
          id="ex3"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="ex3-tab-1"
              data-mdb-toggle="tab"
              href="#ex3-tabs-1"
              role="tab"
              aria-controls="ex3-tabs-1"
              aria-selected="true"
            >
              Basic Info
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-2"
              data-mdb-toggle="tab"
              href="#ex3-tabs-2"
              role="tab"
              aria-controls="ex3-tabs-2"
              aria-selected="false"
            >
              {" "}
              INTRO AI
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-4"
              data-mdb-toggle="tab"
              href="#ex3-tabs-4"
              role="tab"
              aria-controls="ex3-tabs-4"
              aria-selected="false"
            >
              {" "}
              PERSONAL DETAILS
            </a>
          </li>
        </ul>
        {/* <!-- Tabs navs --> */}

        {/* <!-- Tabs content --> */}
        <div className="tab-content" id="ex2-content">
          <div
            className="tab-pane fade show active"
            id="ex3-tabs-1"
            role="tabpanel"
            aria-labelledby="ex3-tab-1"
          >
            {/* first form  */}
            <div className="tab-content mb-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-About Startup"
                role="tabpanel"
                aria-labelledby="v-pills-About Startup-tab"
              >
                <Formik
                  initialValues={{
                    ...currentUser,
                    startupStages: currentUser.startupStages || "", // Set to an empty string if no value
                    startupName: currentUser.startupName || "",
                  }}
                  validationSchema={Schema1}
                  onSubmit={updateUser}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex flex-column">
                        <div className="form-outline mb-2">
                          <div className="file-upload-wrapper">
                            <div className="image-body mt-6 md:mt-0 ml-2">
                              <label
                                htmlFor="startupimage"
                                className="text-base text-black mb-2"
                              >
                                Your profile Image
                              </label>
                              <br />
                              <div className="relative flex items-center justify-center w-20 h-20 rounded-full border-2 border-gray-300 bg-gray-100 overflow-hidden cursor-pointer">
                                {startUpImage || currentUser.startupimage ? (
                                  <img
                                    src={
                                      startUpImage
                                        ? URL.createObjectURL(startUpImage)
                                        : currentUser.startupimage
                                    }
                                    alt="Startup Preview"
                                    className="w-full h-full object-cover object-top rounded-full"
                                  />
                                ) : (
                                  <img
                                    src="/AvatarPlaceholder.svg" // Path to your saved SVG file
                                    alt="Avatar Placeholder"
                                    className="w-[85%] h-[85%]"
                                  />
                                )}
                                <input
                                  type="file"
                                  name="startupimage"
                                  className="absolute inset-0 opacity-0 cursor-pointer"
                                  onChange={(e) =>
                                    handleFileChange(setStartUpImage, e)
                                  }
                                  accept="image/*"
                                />
                              </div>

                              {/* Dynamic text for upload and re-upload */}
                              <p className="mt-2 text-sm font-medium text-gray-500">
                                {startUpImage || currentUser.startupimage
                                  ? "*Click to Re-upload Image"
                                  : "*Click to Upload Image"}
                              </p>

                              {/* Display error message if there's any */}
                              {errors.startupimage && touched.startupimage ? (
                                <div className="text-deny">
                                  {errors.startupimage}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="form-outline mt-4 ms-1">
                          <MDBInput
                            label="Your Name"
                            id="firstName"
                            type="text"
                            value={values.firstName}
                            onChange={handleChange}
                            name="firstName"
                          />
                          {errors.firstName && touched.firstName ? (
                            <div className="text-deny">{errors.firstName}</div>
                          ) : null}
                        </div>

                        <div className="form-outline mt-4 mb-4 ms-1">
                          <MDBInput
                            label="Startup Name"
                            id="startupName"
                            type="text"
                            value={values.startupName}
                            onChange={handleChange}
                            name="startupName"
                          />
                          {errors.startupName && touched.startupName ? (
                            <div className="text-deny">
                              {errors.startupName}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-2 ms-1">
                          <MDBInput
                            label="Website "
                            type="text"
                            id="website"
                            value={values.website}
                            onChange={handleChange}
                            name="website"
                          />
                        </div>

                        <div className="form-outline mt-4 ms-1">
                          <MDBInput
                            label="Amount Raising"
                            id="currentRaiseAmount"
                            type="number"
                            value={values.currentRaiseAmount}
                            onChange={handleChange}
                            name="currentRaiseAmount"
                          />
                        </div>

                        <div className="mb-4 mt-4 ml-1">
                          <label htmlFor="select1">Your Industry</label>
                          <br />
                          <select
                            className="select border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500 md:w-[36rem]"
                            name="industry"
                            value={values.industry}
                            onChange={handleChange}
                          >
                            <option defaultChecked="true">
                              Select Industry
                            </option>
                            {startupIndustries.map((ind) => (
                              <option key={ind.value} value={ind.value}>
                                {ind.text}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 mb-4 ml-1">
                          <label htmlFor="interests" className="mb-2">
                            Interests
                          </label>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            id="interests"
                            options={interestOptions}
                            disableCloseOnSelect
                            value={
                              Array.isArray(values.interests)
                                ? values.interests
                                : values.interests
                                  ? values.interests.split(", ")
                                  : ""
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("interests", newValue);
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Interests"
                                placeholder="Select Interests"
                              />
                            )}
                          />
                          {touched.interests && errors.interests && (
                            <div className="text-deny mt-1">
                              {errors.interests}
                            </div> // Custom error message
                          )}
                        </div>
                      </div>

                      <div className="form-outline mt-4 ms-1">
                        <MDBTextArea
                          label="Brief"
                          type="text"
                          id="brief"
                          rows={2}
                          value={values.brief}
                          onChange={(e) => {
                            const input = e.target.value;
                            const wordCount = input
                              .split(/\s+/)
                              .filter((word) => word.length > 0).length;

                            if (wordCount <= 150) {
                              handleChange(e);
                            }
                          }}
                          name="brief"
                        />
                        {errors.brief && touched.brief ? (
                          <div className="text-deny">{errors.brief}</div>
                        ) : null}
                        <div>
                          Word Count:{" "}
                          {values.brief
                            ? values.brief
                                .split(/\s+/)
                                .filter((word) => word.length > 0).length
                            : 0}
                          /150
                        </div>
                      </div>
                      <FormControl className="form-outline mt-4 ms-1">
                        <FormLabel id="demo-radio-buttons-group-label">
                          Funded Or Bootstrapped
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="none"
                          name="type"
                          id="type"
                          value={values.type}
                          onChange={handleChange}
                        >
                          <div className="form-outline ">
                            <FormControlLabel
                              value="Funded"
                              control={<Radio />}
                              label="Funded"
                            />
                            <FormControlLabel
                              value="Bootstrapped"
                              control={<Radio />}
                              label="Bootstrapped"
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      <FormControl className="form-outline mt-4 ms-1">
                        <FormLabel id="startup-stages-label">
                          Startup Stage
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="startup-stages-label"
                          name="startupStages"
                          value={values.startupStages}
                          onChange={(e) =>
                            setFieldValue("startupStages", e.target.value)
                          }
                        >
                          {startupStages.map((stage) => (
                            <FormControlLabel
                              key={stage.value}
                              value={stage.value}
                              control={<Radio />}
                              label={stage.text}
                            />
                          ))}
                        </RadioGroup>
                        {errors.startupStages && touched.startupStages ? (
                          <div className="text-deny">
                            {errors.startupStages}
                          </div>
                        ) : null}
                      </FormControl>

                      <div className="form-outline mt-4 mb-2"></div>

                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-block mb-1"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* first form end  */}

          {/*second form*/}
          <div
            className="tab-pane fade mb-4"
            id="ex3-tabs-2"
            role="tabpanel"
            aria-labelledby="ex3-tab-2"
          >
            <Formik
              initialValues={{
                intro_message: currentUser.intro_message || "",
                interested_in: currentUser.interested_in || [],
                strongest_skills: currentUser.strongest_skills || [],
                years_of_experience: currentUser.years_of_experience || "",
                support_needed: currentUser.support_needed || [],
              }}
              validationSchema={Schema5}
              onSubmit={(values, { setSubmitting }) => {
                updateUser(values, { setSubmitting });
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <p className="w-80 md:w-[28rem] mb-4 text-sm font-extralight">
                    Change settings here & check back every monday and thursday
                    to see new intros. Your intros will match based on the
                    settings you change below.
                  </p>
                  {/* AI Interests */}
                  <div className="col-12 mb-4">
                    <label htmlFor="interested_in" className="mb-2">
                      What are you interested in?
                    </label>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="interested_in"
                      options={introAIOptions.interests}
                      disableCloseOnSelect
                      value={values.interested_in}
                      onChange={(event, newValue) => {
                        setFieldValue("interested_in", newValue);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Interests"
                          size="small" // Reduce the size of the text field
                          sx={{
                            width: 305, // Adjust width as desired

                            "& .MuiInputLabel-root": {
                              fontSize: "0.875rem", // Adjust label font size
                            },
                          }}
                        />
                      )}
                    />
                    {touched.interested_in && errors.interested_in && (
                      <div className="text-deny mt-1">
                        {errors.interested_in}
                      </div>
                    )}
                  </div>

                  {/* Strongest Skills */}
                  <div className="col-12 mb-4">
                    <label htmlFor="strongest_skills" className="mb-2">
                      What are your strongest skills? (Select up to 2)
                    </label>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="strongest_skills"
                      options={introAIOptions.skills}
                      disableCloseOnSelect
                      value={values.strongest_skills}
                      onChange={(event, newValue) => {
                        if (newValue.length <= 2) {
                          setFieldValue("strongest_skills", newValue);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Limit Exceeded",
                            text: "You can select up to 2 strongest skills only.",
                          });
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Strongest Skills"
                          size="small" // Reduce the size of the text field
                          sx={{
                            width: 305, // Adjust width as desired
                            "& .MuiInputLabel-root": {
                              fontSize: "0.875rem", // Adjust label font size
                            },
                          }}
                        />
                      )}
                    />
                    {touched.strongest_skills && errors.strongest_skills && (
                      <div className="text-deny mt-1">
                        {errors.strongest_skills}
                      </div>
                    )}
                  </div>

                  {/* Experience */}
                  <div className="col-12 mb-4">
                    <label htmlFor="years_of_experience" className="mb-2">
                      How many years of experience do you have?
                    </label>
                    <br />
                    <select
                      className="select border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500 w-[19rem]"
                      name="years_of_experience"
                      value={values.years_of_experience}
                      onChange={handleChange}
                    >
                      <option defaultChecked="true">Select Experience</option>
                      {introAIOptions.experience.map((exp) => (
                        <option key={exp} value={exp}>
                          {exp}
                        </option>
                      ))}
                    </select>
                    {touched.years_of_experience &&
                      errors.years_of_experience && (
                        <div className="text-deny mt-1">
                          {errors.years_of_experience}
                        </div>
                      )}
                  </div>

                  {/* Support Needed */}
                  <div className="col-12 mb-4">
                    <label htmlFor="support_needed" className="mb-2">
                      What support do you need?
                    </label>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="support_needed"
                      options={introAIOptions.skills}
                      disableCloseOnSelect
                      value={values.support_needed}
                      onChange={(event, newValue) => {
                        setFieldValue("support_needed", newValue);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Support Areas"
                          size="small" // Reduce the size of the text field
                          sx={{
                            width: 305, // Adjust width as desired

                            "& .MuiInputLabel-root": {
                              fontSize: "0.875rem", // Adjust label font size
                            },
                          }}
                        />
                      )}
                    />
                    {touched.support_needed && errors.support_needed && (
                      <div className="text-deny mt-1">
                        {errors.support_needed}
                      </div>
                    )}
                  </div>

                  <div className="col-12 mb-4 w-80 md:w-[30rem]">
                    <label htmlFor="intro_message" className="mb-2">
                      Intro Message
                    </label>
                    <MDBTextArea
                      id="intro_message"
                      name="intro_message"
                      rows={4}
                      placeholder="Write your intro message (max 50 words)"
                      value={values.intro_message}
                      onChange={(e) => {
                        handleChange(e);
                        // Optional: Set custom logic to update state if needed
                      }}
                      className={`form-control ${
                        touched.intro_message && errors.intro_message
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {touched.intro_message && errors.intro_message && (
                      <div className="text-deny mt-1">
                        {errors.intro_message}
                      </div>
                    )}
                    {/* Word count display */}
                    <div className="mt-1 text-sm text-gray-600">
                      Word count:{" "}
                      {
                        values.intro_message.trim().split(/\s+/).filter(Boolean)
                          .length
                      }{" "}
                      / 50
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-block"
                    style={{ backgroundColor: "#b0ff4b", color: "black" }}
                  >
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </form>
              )}
            </Formik>
          </div>
          {/*second form end*/}

          {/*third form */}
          <div
            className="tab-pane fade"
            id="ex3-tabs-4"
            role="tabpanel"
            aria-labelledby="ex3-tab-4"
          >
            <div className="tab-content mb-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-Owner And Product Details"
                role="tabpanel"
                aria-labelledby="v-pills-Owner And Product Details-tab"
              >
                <Formik
                  initialValues={currentUser}
                  validationSchema={Schema3}
                  onSubmit={updateUser}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-1 form-floating ">
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label="Email Address"
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                              name="email"
                            />
                            {errors.email && touched.brief ? (
                              <div className="text-deny">{errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label=" Mobile"
                              type="text"
                              id="tel"
                              value={values.tel}
                              onChange={handleChange}
                              name="tel"
                              maxLength={10}
                              minLength={10}
                            />
                            {errors.tel ? (
                              <div className="text-deny">{errors.tel}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-1 form-floating">
                        <div className="col">
                          <div className="col">
                            <div className="form-outline mb-4">
                              <select
                                id="location"
                                onChange={handleChange}
                                value={values.location}
                                className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              >
                                <option value="">Location</option>
                                {countries.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2 form-floating">
                        <div className="col">
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label="LinkedIn Profile"
                                type="text"
                                id="linkedInUrl"
                                value={values.linkedInUrl}
                                onChange={handleChange}
                                name="linkedInUrl"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-block mb-1"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/*third form end */}
        </div>
      </div>
    </>
  );
};

export default Register;
