const InvesterListComingSoon = () => {
  return (
    <div className=" w-full h-screen flex justify-center pt-80 ">
      <span className="text-2xl text-black tracking-wide font-semibold">
        Coming Soon...
      </span>
    </div>
  );
};

export default InvesterListComingSoon;
