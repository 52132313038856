import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/main/home", current: true },
  { name: "About us", href: "/main/aboutus", current: false },
  { name: "FAQs", href: "/main/faq", current: false },
  { name: "Become a kreedify pro", href: "/main/scout", current: false },
  { name: "News", href: "/main/newsbrowser", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const currentStartup = JSON.parse(sessionStorage.getItem("startup"));
  return (
    <Disclosure as="nav" className="shadow-md bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-20 px-4 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="ml-4 sm:ml-0 h-24 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  {open ? (
                    <XMarkIcon
                      className="block h-64 w-6 text-black"
                      style={{ height: "40px", width: "40px" }}
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-64 w-6 text-black"
                      style={{ height: "40px", width: "40px" }}
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex">
                <div className="">
                  <NavLink to="/main/home">
                    <img className="h-40 w-22" src="/logo.png" alt="Kreedify" />
                  </NavLink>
                </div>
                <div className="hidden pt-16 sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          "text-black",
                          "rounded-md px-3 py-2 text-2xl font-medium -mt-2",
                          (item.name === "About us" || item.name === "FAQs") &&
                            "hidden",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden absolute inset-y-0 space-x-2 right-0 sm:flex items-center pr-28 -ml-4">
                {currentStartup === null ? (
                  <>
                    <Link
                      className="btn normal-case font-medium text-sm rounded-lg text-center px-3 py-2.5 bg-black text-white"
                      to="/main/signup"
                    >
                      Join for free
                    </Link>

                    <Link
                      className="btn normal-case font-medium text-sm rounded-lg text-center px-3 py-2.5 bg-black text-white"
                      to="/main/login"
                    >
                      Login
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation
                .filter(
                  (item) => item.name !== "About us" && item.name !== "FAQs",
                )
                .map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? "text-black" : "text-black",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              {/* Exclude "Contact" from mobile menu */}
              <NavLink
                to="/main/signup"
                className="text-black block rounded-md px-3 py-2 text-base font-medium"
              >
                Join for free
              </NavLink>
              <NavLink
                to="/main/login"
                className="text-black block rounded-md px-3 py-2 text-base font-medium"
              >
                Login
              </NavLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
