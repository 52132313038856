// contexts/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import app_config from "../config";

const SocketContext = createContext();

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const currentUser = JSON.parse(sessionStorage.getItem("startup"));

  useEffect(() => {
    const newSocket = io(app_config.apiurl, { autoConnect: false });
    newSocket.connect();

    if (currentUser?._id) {
      newSocket.emit("addtocontact", currentUser._id);
    }

    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, [currentUser?._id]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

export const useSocket = () => useContext(SocketContext);
