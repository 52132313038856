import React, { useState, useEffect } from "react";

const TabNavigation = () => {
  const [activeTab, setActiveTab] = useState("welcome");

  const tabs = ["My Profile", "Cofounder Matching", "Kreedify Pro", "News"];

  useEffect(() => {
    const tabSwitchInterval = setInterval(() => {
      setActiveTab((prevTab) => {
        const currentIndex = tabs.indexOf(prevTab);
        const nextIndex = (currentIndex + 1) % tabs.length;
        return tabs[nextIndex];
      });
    }, 2000);

    return () => clearInterval(tabSwitchInterval);
  }, [tabs]);

  const renderContent = () => {
    switch (activeTab) {
      case "My Profile":
        return (
          <div className="flex justify-center md:-mt-2 w-[110%] md:w-[80%]">
            <img
              src="/My_profile_mobileMockup.png"
              alt="My profile mockup"
              className="h-[400px] block md:hidden"
            />
            <img
              src="/My_profile_pc.jpg"
              alt="My profile pc mockup"
              className="h-96 hidden md:block shadow-lg rounded-xl"
            />
          </div>
        );
      case "Cofounder Matching":
        return (
          <div className="flex justify-center md:-mt-2 w-[110%] md:w-[80%]">
            <img
              src="/cofounder_matching_mobileMockup.png"
              alt="cofounder matching mockup"
              className="h-[400px] block md:hidden"
            />
            <img
              src="/cofounder_matching_pc.jpg"
              alt="cofounder matching pc mockup"
              className="h-96 hidden md:block shadow-lg rounded-xl"
            />
          </div>
        );
      case "Kreedify Pro":
        return (
          <div className="flex justify-center md:-mt-2 w-[110%] md:w-[80%]">
            <img
              src="/Kreedify_pro_list_mobileMockup.png"
              alt="kreedify pro list mockup"
              className="h-[400px] block md:hidden"
            />
            <img
              src="/Kreedify_pro_list_pc.jpg"
              alt="kreedify pro list pc mockup"
              className="h-96 hidden md:block shadow-lg rounded-xl"
            />
          </div>
        );
      case "News":
        return (
          <div className="flex justify-center md:-mt-2 w-[110%] md:w-[80%]">
            <img
              src="/news_page_mobileMockup.png"
              alt="news mockup"
              className="h-[400px] block md:hidden"
            />
            <img
              src="/news_page_pc.jpg"
              alt="news mockup"
              className="h-96 hidden md:block shadow-lg rounded-xl"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* Tabs Navigation */}
      <div className="flex justify-center mt-12 md:mt-16 w-full md:!px-4 px-2">
        <div className="bg-white border border-gray-200 rounded-full p-1 md:!p-2 flex space-x-2 md:!space-x-4 overflow-x-auto">
          <div className="flex space-x-2 md:!space-x-4 flex-nowrap">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`md:!px-4 px-2 py-2 rounded-full text-xs md:!text-sm ${
                  activeTab === tab
                    ? "bg-black text-white shadow-lg"
                    : "text-black"
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Tab Content */}
      <div className="p-2 md:!p-4 rounded w-full md:w-2/3 mx-auto mt-2 md:!mt-4 mb-4 flex flex-col items-center justify-center">
        {renderContent()}
      </div>
    </>
  );
};

export default TabNavigation;
