import React, { useState } from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // MUI Icon for Accept
import CancelIcon from "@mui/icons-material/Cancel"; // MUI Icon for Deny
import { IconButton } from "@mui/material"; // MUI Icon Button
import { checkProfileImageExist, isAvatarImageAvailable } from "../../util.js";

const MyNetwork = ({
  approvedRequests,
  pendingRequests,
  invitations,
  handleConnectionRequest,
}) => {
  const [activeTab, setActiveTab] = useState("approved");

  const renderApprovedTab = () => (
    <div className="py-2  mb-4 md:-mt-8 -ml-4 md:-ml-12 md:!py-10">
      {approvedRequests.length === 0 ? (
        <p className="text-center mt-8 md:ml-32 mb-4">
          Yet to create your network.
        </p>
      ) : (
        approvedRequests.map((request, i) => {
          let avatar;
          let navlink;
          if (request.role === "startup") {
            avatar = request.startupimage;
            navlink = `/startup/introai/${request._id}`;
          } else if (request.role === "investor") {
            avatar = request.investoravatar;
            navlink = `/startup/investordetails/${request._id}`;
          }

          return (
            <div
              key={request._id}
              className="flex flex-col !ml-9 md:-ml-8 md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    {checkProfileImageExist(avatar) ? (
                      <img
                        className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                        src={avatar}
                        alt="avatar"
                      />
                    ) : (
                      <AlphabetAvatar
                        alphabet={request.firstName.charAt(0)}
                        className="!h-16 !w-16 md:h-16 md:w-16"
                      />
                    )}
                  </div>

                  <h2 className="text-base md:text-xl font-medium capitalize">
                    {request.firstName}{" "}
                    {request.role === "investor" && request.lastName}
                  </h2>
                </div>

                <div className="md:hidden flex-shrink-0 mr-3">
                  <Link
                    className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                    to={navlink}
                  >
                    <ArrowForwardIcon />
                  </Link>
                </div>

                <div className="hidden md:block mr-3">
                  <Link
                    className="bg-white border-2 border-black text-black p-2 rounded"
                    to={navlink}
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );

  const renderPendingTab = () => (
    <div className="py-2  mb-4 md:-mt-8 -ml-4 md:-ml-12 md:!py-10">
      {pendingRequests.length === 0 ? (
        <p className="text-center mt-8">No Pending requests</p>
      ) : (
        pendingRequests.map((request) => {
          let avatar;
          let navlink;
          if (request.role === "startup") {
            avatar = request.startupimage;
            navlink = `/startup/introai/${request._id}`;
          } else if (request.role === "investor") {
            avatar = request.investoravatar;
            navlink = `/startup/investordetails/${request._id}`;
          }
          return (
            <div
              key={request._id}
              className="flex flex-col !ml-9 md:-ml-8 md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    {checkProfileImageExist(avatar) ? (
                      <img
                        className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                        src={avatar}
                        alt="avatar"
                      />
                    ) : (
                      <AlphabetAvatar
                        alphabet={request.firstName.charAt(0)}
                        className="!h-16 !w-16 md:h-16 md:w-16"
                      />
                    )}
                  </div>

                  <h2 className="text-base md:text-xl font-medium capitalize">
                    {request.firstName}{" "}
                    {request.role === "investor" && request.lastName}
                  </h2>
                </div>

                <div className="md:hidden flex-shrink-0 mr-3">
                  <Link
                    className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                    to={navlink}
                  >
                    <ArrowForwardIcon />
                  </Link>
                </div>

                <div className="hidden md:block mr-3">
                  <Link
                    className="bg-white border-2 border-black text-black p-2 rounded"
                    to={navlink}
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );

  const renderInvitationsTab = () => (
    <div className="py-2  mb-4 md:-mt-8 md:-ml-8 md:!py-10">
      {invitations.length === 0 ? (
        <p className="text-center mt-8">No Invitations</p>
      ) : (
        invitations.map((invitationData) => {
          let invitation = invitationData.sender;
          return (
            <div
              key={invitation._id}
              className="flex items-center bg-white rounded-lg mx-3 md:mx-0 shadow-md py-4 md:py-8 px-3 md:px-0 mb-4 md:w-[40rem] gap-2 ml-4"
            >
              {/* Profile Picture - Avatar */}
              {isAvatarImageAvailable(invitation.startupimage) ? (
                <img
                  className="h-16 w-16 rounded-full mr-4"
                  src={invitation.startupimage}
                  alt="avatar"
                />
              ) : (
                <AlphabetAvatar
                  alphabet={invitation.firstName.charAt(0)}
                  className="h-16 w-16 rounded-full mr-4"
                />
              )}

              {/* Name and View Profile */}
              <div className="flex-1">
                <h2 className="text-base md:text-2xl font-medium capitalize mb-2 md:!mb-4 ">
                  {invitation.firstName}
                </h2>
                <Link
                  className="text-sm md:text-base border-2 border-black bg-white text-black md:!p-2 p-1 rounded"
                  to={"/startup/introai/" + invitation._id}
                >
                  View Profile
                </Link>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-2">
                {/* Icon buttons for mobile */}
                <div className="md:hidden">
                  <IconButton
                    aria-label="accept"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, true)
                    }
                  >
                    <CheckCircleIcon
                      fontSize="large"
                      className="text-skincolor"
                    />
                  </IconButton>
                  <IconButton
                    aria-label="deny"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, false)
                    }
                  >
                    <CancelIcon fontSize="large" className="text-deny" />
                  </IconButton>
                </div>

                {/* Regular buttons for desktop */}
                <div className="hidden md:flex justify-center w-full">
                  <button
                    className="bg-skincolor text-black px-2 py-1 rounded w-20 h-10"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, true)
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="bg-deny text-black px-2 py-1 rounded w-20 h-10 ml-2"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, false)
                    }
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );

  return (
    <div className="py-2 px-2 mb-4 md:-mt-8  md:ml-4 md:!py-10">
      {/* Tab Navigation */}
      <div className="flex justify-center md:justify-start mb-4 ">
        <button
          className={`px-6 py-2 rounded-l-full ${
            activeTab === "approved"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-black"
          }`}
          onClick={() => setActiveTab("approved")}
        >
          Connections
        </button>
        <button
          className={`px-6 py-2 ${
            activeTab === "pending"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-black"
          }`}
          onClick={() => setActiveTab("pending")}
        >
          Pending
        </button>
        <button
          className={`px-6 py-2 rounded-r-full ${
            activeTab === "invitations"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-l-1 border-black"
          }`}
          onClick={() => setActiveTab("invitations")}
        >
          Invitations
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "approved"
        ? renderApprovedTab()
        : activeTab === "pending"
          ? renderPendingTab()
          : renderInvitationsTab()}
    </div>
  );
};

export default MyNetwork;
